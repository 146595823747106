import React from "react"
import { graphql, PageProps } from "gatsby"
import { Kind } from "../../../common/kind"
import { ArticleListPageQuery } from "../../../types/gatsby-graphql.generated"
import { compareByDate } from "../../base/date"
import { ListPageHeadLayout, ListPageLayout } from "../../layouts/list"
import { InfoWith, ListItem } from "../../views/content"

export function Head() {
    return <ListPageHeadLayout isHome={false} kind={Kind.Article} />
}

export default function ArticleListPage({
    data,
}: PageProps<ArticleListPageQuery>) {
    return (
        <ListPageLayout
            isHome={false}
            kind={Kind.Article}
            items={data.allMarkdownRemark.nodes
                .sort(compareByDate)
                .map(node => (
                    <ListItem
                        key={node.path}
                        path={node.path}
                        title={node.title}
                        info={<InfoWith {...node} />}
                    />
                ))}
        />
    )
}

export const pageQuery = graphql`
    query ArticleListPage {
        allMarkdownRemark(
            filter: {
                frontmatter: { kind: { eq: "article" } }
                published: { eq: true }
            }
        ) {
            nodes {
                contentId
                path
                title
                updatedAt
                updatedAtTimestamp
                publishedAt
                publishedAtTimestamp
                topics {
                    path
                    title
                }
                parentSeries {
                    path
                    title
                    items {
                        contentId
                    }
                }
            }
        }
    }
`
